
.site-layout .site-layout-background {
  background: #fff;
}

.action {
  cursor: pointer;
  align-items: center;

  &:hover {
    opacity: .7;
  }
}


.userName {
  padding: 5px;
}

.adminLayout a {
  color: #fff;
}

.adminLayout a :hover {
  color: #1890ff;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: white;
}

.ant-menu-item-selected a {
  color: black !important;
}

.ant-menu {
  transition: none;
}

.ant-menu-submenu-title .anticon + span {
  transition: none;
}

.ant-menu-item, .ant-menu-submenu-title, .ant-menu {
  transition: none;
}

.ant-tooltip-inner{
  padding: 0 !important;
  .ant-menu {
    margin: 0;
    padding: 0;
    font-size: 12px;
  }
  .ant-menu-item {
    line-height: 27px !important;
    padding-inline: 8px !important;
    height: auto !important;
  }
}
.ant-menu-item-selected{
  .ant-menu-title-content{
    color: black !important;
  }

}
@primary-color: #1890ff;